.accordion-container {
  display: none;
}
@media (max-width: 820px) {
  .accordion-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
}


.accordion-item {
  margin-bottom: 10px;
  width: 300px;
}

@media (min-width: 768px) {
.accordion-item {
  width: 400px;
}

}

.accordion-button {
  width: 100%;
  padding: 5px;
  font-size: 16px;
  text-align: left;
  font-weight: 600;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  border: none;
  border-bottom: 1px solid #eaeaea;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accordion-button:first-of-type { 
  border-top: 1px solid #eaeaea;
}


.accordion-content {
  padding: 15px;
  border: none;
  border-top: none;
}

.accordion-content h4 {
  margin-top: 0;
  color: #333;
}

.accordion-content p {
  margin: 5px 0;
  color: #555;
}
