body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  color: #333;
}

.terms-container {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.terms-header {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

h2 {
  font-size: 1.5rem;
  margin: 20px 0 10px;
  color: #444;
}

p {
  line-height: 1.6;
  margin-bottom: 16px;
}

.terms-containe.a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
