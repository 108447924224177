.wrapper-footer{
  position: relative;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  width: 100%;
  padding: 20px 0; /* Add padding for mobile */
}


.container-footer {
  display: flex;
  flex-direction: column; /* Stack on smaller screens */
  align-items: center;
  padding: 20px 0px; /* Adjust padding for smaller screens */
}


.center-footer {
  flex: 1;
}

.right-footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 20px 0;
}

.left-footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Spread items across the entire width */
  align-items: center;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.left-footer-container > * {
    flex: 1; /* Make each child take up equal space */
}

.footer-menus {
  padding-top: 0;
  padding-right: 15px;
  width: auto;
  height: 150px;
}

.footer-menus h3 {
  font-size: 14px;
}

.footer-icons-container {
  display: flex;
  gap: 10px; 
  justify-content: center;
  padding-top: 10px;
  
}

@media (min-width:820px) {
  .footer-icons-container {
    margin-right: 50px;
  }
}

.footer-business-info {
  font-size: 14px; /* Slightly smaller font for mobile */
  text-align: center;
}

h3{
  padding-bottom: 10px;
}

.footer-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-ul li {
  font-size: 14px;
}
.footer-menus-item{
  font-size: 16px;
  margin-bottom: 11px;
}

.footer-tag {
  text-align: center;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (min-width: 768px) {
  .container-footer {
    flex-direction: row;
    justify-content: space-between;
    padding: 54px 30px 69px; /* Original padding for desktop */
  }

  .left-footer-container {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 0;
  }

  .footer-menus {
    margin: 0 70px 0 0;
  }

  .right-footer-container {
    align-items: flex-end;
    text-align: right;
  }

  .footer-tag {
    padding-left: 30px;
    padding-bottom: 20px;
    text-align: left;
  }
}