a {
  color: inherit;
  transition: color .3s ease-out;
  background-color: rgba(0, 0, 0, 0);
}

img {
  width: auto; 
  height: auto;
  object-fit: cover;
  justify-content: center;
}

.p-center {
  top: 50%;
  left: 50%;
}

.lazyloaded{
  opacity: 1;
}


.this-product-item {
  width: 100%;
  
}


.product-item-images {
  opacity: 1;
  background-color: rgb(247 247 247);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-item-image {
  position: relative;
  justify-content: center;
  z-index: 2;
  aspect-ratio: 4/5;

}

.product-item-image.product-item-image-first-image {
  width: auto;
  height: auto;
}

.product-item-image-holder {
  position: relative;
  display: block;
  padding-top: 0 !important;
  transition: opacity .15s;
  width: 100%;
  height: auto;
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;
}

.product-item-info {
  margin-top: 1.25rem/* 20px */;
  display: flex;
  justify-content: space-between;
}

.product-item-title-container {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.product-item-title {
  text-align: left;
  margin-top: 0px !important;
  line-height: .75rem/* 12px */;
  margin-bottom: 4px;

  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 4px;
  text-decoration: none;
  font-size: 18px;
  display: inline-block;
  position: relative;
}

@media (max-width:600px) {
  .product-item-title {
    line-height: 1;
  }
}

.product-item-artist {
  text-align: left;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 3px;
  line-height: 24px;
  font-size: 16px;
  display: inline-block;
  position: relative;
  transition: .4s cubic-bezier(0.39, 0.575, 0.565, 1);
  text-decoration: none;
}

.frame-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%; 
  pointer-events: none;
  z-index: 1;
}

[data-frame].lazyloaded>.object-fit {
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.product-item-save {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;


  &:hover{
    background-color: aliceblue;
    transform: scale(1.1);
  }
}