.category-navigation-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding-top: 100px;  
  z-index: 999;
}

/* Category Links */
.category-navigation-links {
  list-style: none;
  padding: 0;
  text-align: center;
}

.category-navigation-links li {
  margin: 20px 0;
}

.category-navigation-links a {
  color: black;
  text-decoration: none;
  font-size: 1.5rem;
}

/* Back Button */
.back-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 30px;
  background: none;
  border: none;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
}

.cat-nav-back {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (min-width:400px) {
  .cat-nav-back span {
    margin-left: 80px;
  }
}
@media (min-width:500px) {
  .cat-nav-back span {
    margin-left: 110px;
  }
}