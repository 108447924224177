body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  color: #333;
}

.privacy-container, .return-container {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.privacy-header, .return-header {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

h2 {
  font-size: 1.5rem;
  margin: 20px 0 10px;
  color: #444;
}

h3 {
  font-size: 1.2rem;
  margin: 15px 0 8px;
  font-style: italic;
}

p {
  line-height: 1.6;
  margin-bottom: 16px;
}
.privacy-list.li {
  line-height: 1.6;
  margin-bottom: 16px;
}

.privacy-list{
  list-style-type: disc;
  /* margin-left: 20px; */
  list-style: none;

}
.privacy-container.a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
