.mobile-navigation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  padding-top: 100px;  
  z-index: 999;
  overflow-y: auto; /* Allow scrolling within the menu */

}

.mobile-navigation-links {
  list-style: none;
  padding: 0;
  text-align: center;
  color: black;
}

.mobile-navigation-links li {
  margin: 20px 0;
}

.mobile-navigation-links a {
  color: black;
  text-decoration: none;
  font-size: 1.5rem;
}

.list-close.js-menu-shop-close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  transition: transform 0.3s;
}

.list-close.js-menu-shop-close:hover {
  transform: scale(1.1);
  background-color: rgba(0, 0, 0, 0.9);
}