body {
  line-height: inherit;
}

div{
  box-sizing: border-box;
}

.wrapper-full-width {
  margin-left: auto;
  margin-right: auto;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 80px;
  box-sizing: border-box;
}
@media (max-width:768px) {
  .wrapper-full-width {
    padding-bottom: 8px;

  }
}
select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 40px;
}

.select-hide {
  display: none;
}

.select-items {
  position: absolute;
  top: calc(100% - 1px);
  right: 0;
  left: 0;
  z-index: 9;
  border-top: 1px solid #eaeaea;
  background-color: #fff;
  min-width: 200px;
  max-height: 240px;
  overflow: auto;
}

.select-with-scroll-bar {
  overflow-y: scroll;
  border-bottom: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
}

.product-collection-container {
  margin-bottom: 50px;
}

.product-list-header-wrapper {
  margin-top: 70px;
  text-align: center;
  margin-bottom: 50px;
  padding: 0 15px;

}

.product-list-container {
  width: 100%;
  
  box-sizing: border-box;
}

.product-list-header-title {
  margin-bottom: 0;
  line-height: normal;
  font-size: 60px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: benton-sans-compressed,sans-serif;
  font-weight: 700;
  font-style: normal;
}

.product-list-header-description {
  margin-left: 334px;
  margin-right: 334px;
}

.product-list-page-filter-container {
  transition: .4s;
  display: flex;
  position: sticky;
  top: 0;
  max-width: 100%;
  width: 100%;
  justify-content: space-between;
  z-index: 100;
  background-color: #fff;
  text-align: center;
  border-top: 1px solid rgb(239, 239, 239);
  border-bottom: 1px solid rgb(239, 239, 239);
  padding: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

@media (max-width: 820px) {
  .product-list-page-filter-container {
    transition: .4s;
    display: flex;
    flex-direction: column;
    top: 0;
    max-width: 100%;
    width: 100%;
    justify-content: space-between;
    z-index: 100;
    background-color: #fff;
    text-align: center;
    border-top: 1px solid rgb(239, 239, 239);
    border-bottom: 1px solid rgb(239, 239, 239);
    padding: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    } 
}

@media (max-width: 600px) {
  .product-list-page-filter-container {
  transition: .4s;
  position: relative;
  display: inline-block;
  top: 0;
  max-width: 100%;
  width: 100%;
  justify-content: space-between;
  z-index: 100;
  background-color: #fff;
  text-align: center;
  border-top: 1px solid rgb(239, 239, 239);
  border-bottom: 1px solid rgb(239, 239, 239);
  padding: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  } 
}

.product-filter-container {
  display: inline-block;
  text-align: left;
}
.product-list-filter-container {
  display: flex;
  flex-direction: column; /* Stack filters vertically on mobile */
  width: 100%;
  margin-bottom: 20px;
  align-items: center;

}

.product-list-dropdown-container {
  position: relative;
  min-width: 300px;
  width: 300px;
  font-family: 'Roboto';
  color: #777;
  font-weight: 500;
  background-color: #fff;
  border-radius: 3px;
  transition: all 375ms ease-in-out;
  select {
  position: absolute;
  overflow: hidden;
  height: 0px;
  opacity: 0;  
    z-index: -1;
  }
}

@media (min-width: 768px) {
  .product-list-dropdown-container {
    width: 300px; /* Fixed width on larger screens */
    margin-right: 20px;
  }
}

@media (max-width: 600px) {
  .product-list-dropdown-container {
    width: 100%; /* Full width on mobile */
    margin-bottom: 15px; /* Spacing between dropdowns on mobile */
  }
}
.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 100;
  display: none;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.dropdown-list-text {
  padding: 10px 15px;
  transition: background-color 0.3s;
  cursor: pointer;
  font-family: 'roboto';
}

.dropdown-placeholder {
  height: 46px;
  width: 200px;
  border: 1px solid #eaeaea;
  padding: 10px;

  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  transition: .4s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.dropdown-select-items {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 8px 15px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  

}

.dropdown-select-items.select-with-scroll-bar {
  border: 1px solid #ccc;
}

.product-list-dropdown-container .dropdown-list {
  display: block;
}

.dropdown-list div:hover {
  background-color: #f2f2f2;
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transition: transform 0.3s;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.category-filter-dropdown {
  -webkit-font-smoothing: antialiased;
  border: 1px solid #eaeaea;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-position: right 10px center;
  cursor: pointer;
  padding: 14px 36px 10px 12px;
  text-indent: .01px;
  text-transform: capitalize;
  line-height: 20px;
}

.product-list-filter-frame-selector-container{
  display: inline-block;
  text-align: left;
}

.product-list-filter-label {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 7px;
  font-size: 12px;
}

@media (max-width: 600px) {
  .product-list-filter-label {
    font-size: 12px;
  }
}

.product-list-frame-selector-color-label {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  letter-spacing: 0;
  color: #666;
  font-size: 11px;
  transition: opacity .4s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.frame-selector-swatch.wood{
  background-image: 'https://images.pexels.com/photos/172295/pexels-photo-172295.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1';
}

.product-list-frame-options-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

@media (min-width: 745px) {
  .product-list-frame-options-container {
    justify-content: flex-start;
  }
}

.product-list-frame-option-selector {
  margin-right: 17px;
  display: flex;
  flex-direction: column;
   
}
@media (max-width: 768px) {
  .product-list-frame-option-selector {
    /* margin-right: 17px;
    display: flex;
    flex-direction: column; */
     margin-right: 10px;
  
  }
}
.product-list-page-items-container {
  padding-bottom: 6rem/* 96px */;
}


.product-item-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: 2rem/* 32px */;
  column-gap: 1.25rem/* 20px */ !important;
}


@media (max-width: 768px) {
  .product-list-page-items-container {
    padding-top: 2rem;
    padding-bottom: .5rem;
  }
}

@media (min-width: 640px) {
  .product-item-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 30px !important;
  }
}

@media (min-width: 768px) {
  .product-item-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 30px !important;
  }
}

@media (min-width: 1024px) {
  .product-item-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 2.5rem/* 40px */ !important;

  }
}



@media (max-width: 600px){
  .category-filter-dropdown {
    padding: 10px;
    width: 100%; /* Full width on mobile */
  }
}
@media (min-width: 1100px){
  .category-filter-dropdown {
      min-width: 200px;
  }
}

@media (min-width: 745px){
  .product-list-filter-container {
    flex-direction: row; /* Row layout on larger screens */
    justify-content: flex-start;
    width: auto;  }
}

@media (min-width: 1110px) {
  .show-on-tablet-down-only {
    display: none !important;
  }
}

@media (min-width: 1100px) {
  .product-list-page-filter-container{
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 38px 30px 40px;
  }
}

@media (min-width: 1100px) {
  .product-list-filter-container {
    width: auto;
  }
}

@media (min-width: 1100px) {
  .product-filter-container {
    margin-right: 28px;
  }
}

@media (min-width: 1100px) {
  .product-list-page-filter-container.navbar-visible-b {
    top: 70px;
  }
}

@media (max-width: 600px) {
  .product-list-header-title {
    font-size: 48px;
  }
}

@media (max-width: 875px) {
  .product-list-header-description {
    margin-left: auto;
    margin-right: auto;
    max-width: 90%; /* Limit width on smaller screens */
    font-size: 14px; /* Adjust font size for readability */
  }
}

