[type=submit], button {
  /* -webkit-appearance: button; */
  background-color: transparent;
  background-image: none;
}

.hide {
  display: none !important;
}

.membership-modal-container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100dvh;
  background: rgba(0,0,0,.5);
  z-index: 1000;
  display: none;
  align-items: center;
  justify-content: center;
}

.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.full-size .flex-container {
    justify-content: flex-start;
    overflow: scroll;
}

.c-membership-modal {
  background-color: #fff;
  width: 100%;
  max-width: 1060px;
  height: 85%;
  max-height: 730px;
  display: flex;
  justify-content: space-around;
  
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.full-size .c-membership-modal {
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  min-height: 675px;
  justify-content: center;
  align-items: center;

}

.c-membership-modal p {
  line-height: 24px;
  font-size: 16px;
  text-align: center;
  margin-bottom: 16px;
}

.c-membership-modal button {
  display: block;
  transition: background-color .25s,color .25s,border-color .25s;
  border: 1.5px solid #2e2e2e;
  background-color: transparent;
  width: 100%;
  height: 40px;
  cursor: pointer;

  font-family: benton-sans-compressed,sans-serif;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-display: block;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.c-membership-modal button:first-of-type {
  /* background-color: #af9d81; */
  border-color: transparent;
  margin-top: 3px;
}

.c-membership-modal h2 {
  text-align: center;
}

.c-membership-modal h4, .c-membership-modal h2 {
  font-family: benton-sans-compressed,sans-serif;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-display: block;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;

}

.c-membership-modal h4:last-of-type {
  text-align: center;
  margin-top: 12px;
}

.c-membership-modal .c-mm-login h4 {
  text-align: center;
  margin: 10px;
}

.c-mm-login.account-form {
margin-left: 40px;}

.account-form {
  margin: 0 auto;
}

.full-size .account-form {
  z-index: 1;
  width: 90%;
  margin: 0;
  color: #fff;
}

.account-form h2 {
  font-family: benton-sans-compressed,sans-serif;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-display: block;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  font-size: 28px;
  margin-bottom: 16px;
}

.account-form h4 {
  margin: 0;
  font-family: benton-sans-compressed,sans-serif;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-display: block;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;

}

.account-form p {
  line-height: 24px;
  font-size: 16px;
  text-align: center;
}

.account-form input {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 6px 12px 5px;
  border: 1px solid #666;
  margin: 12px 0 0;
  text-align: center;
  line-height: normal;
  border-radius: 0;
  
  height: 44px;
  width: 100%;
  border-radius: 0;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(221 221 221/var(--tw-border-opacity));
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  /* color: rgb(46 46 46/var(--tw-text-opacity)); */
}

.account-form .error-container {
  color: #9f0000;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 12px;
}

.account-form button {
  display: block;
    width: 100%;
    height: 40px;
    text-transform: uppercase;
    font-family: benton-sans-compressed,sans-serif;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-display: block;
    font-weight: 700;
    letter-spacing: 1px;
    border: 1.5px solid #000;
    cursor: pointer;
}

.account-form form .modal-submit {
  margin-top: 30px;
  color: #fff;
  background-color: #ac9e84;
  border: 1.5px solid #ac9e84;
  margin-bottom: 20px;
}

.account-form h4 span {
  text-decoration: underline;
}

.c-membership-modal .account-form {
  width: 50%;
}

.c-membership-modal div.c-mm-signup {
  display: none;
}

.form-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.metafields_form {
  margin-bottom: 0.5rem/* 8px */;
}

.modal-submit {
  min-height: 46px !important;
  border-style: none !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(46 46 46 / var(--tw-bg-opacity)) !important;
  &:disabled {
    color: #000;
    cursor: not-allowed;
  }
}

.modal-submit:hover {
  opacity: 0.8;
}

.modal-submit:active {
  opacity: 1;
}

.c-mm-login-btn {
  margin-top: 24px!important;
}



.c-membership-modal .c-mm-login-btn, .c-membership-modal .c-mm-signup-btn {
  cursor: pointer;
}

.c-membership-modal .c-mm-right {
  width: 50%;
  margin: 40px;
  position: relative;
  background-size: cover;
  background-position: 50%;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/print-shop-635e7.appspot.com/o/HERO%20IMAGE%2F215SKYLINE-BW.jpg?alt=media&token=797c3ffd-59a8-4015-a158-59253817cc1b');
  background-color: #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-mm-right {
  /* position: relative;
  height: calc(100% - 80px) !important; */
}

.full-size .c-membership-modal .c-mm-right {
  width: auto;
  height: auto;
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  margin: 0;
  z-index: 0;
  align-items: flex-start;
  filter: brightness(80%);

}

.modal-x {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 25px;
 
  color: #fff;
  transition: .25s;

}

.modal-x:hover {
  color: #f0e9d2; /* Light creamy color on hover for contrast */
  transform: scale(1.1); /* Slightly enlarges on hover */
  background-color: rgba(0, 0, 0, 0.85); /* Darker on hover for emphasis */
}


@media (min-width: 745px) {
  .c-membership-modal {
    min-height: 650px;
  }
}
@media (min-width: 745px) {
  .full-size .c-membership-modal .c-mm-right {
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
  }
}


@media (min-width: 745px) {
  .full-size .account-form {
    width: 50%;
  }
}

@media (max-width: 500px) {
  .c-membership-modal .c-mm-right.login {
    display: none;
  }
}

@media (max-width: 500px) {
  .c-mm-login.account-form {
     margin: 0 auto;
   }
}
@media (max-width: 500px) {
  .account-form .error-container {
    margin-left: 75px;
  }
}
@media (min-width: 745px) {}
@media (min-width: 745px) {}
@media (min-width: 745px) {}
@media (min-width: 745px) {}
@media (min-width: 745px) {}
