.slick-slider.slick-dotted {
  margin-bottom: 0;
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.new-arr-slick-track {
  display: flex;
  flex-direction: row; 
  justify-content: space-between; 
  align-items: center; 
  flex-wrap: wrap; 
}

.featured-products-container {
  padding: 0;
  width: 100%;
  margin: 80px auto;
  padding-top: 24px;
  padding-bottom: 50px;
}

.featured-products-title {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: benton-sans-compressed,sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  font-style: normal;
  font-display: block;
  text-align: center;
  line-height: 18px;
  letter-spacing: 1.27px;
  font-size: 28px;
}

.featured-products-container .featured-products-title {
  padding: 0 var(30px);
}

.featured-products-product-container {
  margin: 0 15px;
}

@media (min-width: 745px) {
  .featured-products-container {
    margin: 0;
    padding-top: 50px;
    padding-bottom: 50px;
    /* font-size: 20px;
    line-height: 24px; */
  }
}


@media (min-width: 745px) {
  .featured-products-title {
    margin-bottom: 15px;
    line-height: 36px;
    letter-spacing: 1.2px;
    font-size: 36px;
  }
}
@media (max-width: 745px) {
  .new-arr-slick-track {
    display: flex;
    flex-direction: column; /* Stack items vertically on small screens */
    align-items: center; 
  }
}
@media (max-width: 745px) {
  .featured-products-product-container {
    padding: 0 10px;
  }
}



