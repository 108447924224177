body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  color: #333;
}

.contact-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.contact-header {
  font-size: 2.5rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.image-placeholder {
  height: 200px;
  background-color: #e0e0e0;
  margin-bottom: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #888;
  font-size: 1rem;
  text-transform: uppercase;
}

.contact-info {
  text-align: center;
  margin-bottom: 30px;
  font-size: 1.2rem;
}

.contact-info a {
  color: #007bff;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

label {
  font-size: 1rem;
  margin-bottom: 5px;
  font-weight: bold;
}

input,
textarea {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

textarea {
  resize: vertical;
}

.submit-button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-start;
}

.submit-button:hover {
  background-color: #333;
}
