.containerSlider {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: white;
  position: relative;
  overflow: hidden;
}

.hero-banner {
  position: relative;
  margin: 30px auto;
  padding: 0 30px;
}

.slide {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: aliceblue;
}

.imgContainer {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  top: 0;
  left: 0;
  flex: 1;
}

.image{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  object-fit: cover;
}

.infoContainer {
  flex: 1;
  padding: 50px;
  text-align: center;
  color: white;
}

.titleInfo{
  font-size: 70px;
}

.descInfo {
  margin: 50px 0px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
}

.buttonInfo {
  padding: 10px;
  font-size: 20px;
  background-color: dimgray;
  cursor: pointer;
  color: white;
}