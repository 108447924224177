.container {
  height: 30px;
  background-color: white;
  color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #eaeaea ;

  position: relative;
  z-index: 100; 
  
}
