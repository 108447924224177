.image-NewArrItems {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.featured-product-carousel-item {
  display: block;
  margin-right: 15px;
  margin-left: 15px;
  text-align: center;
  text-decoration: none;
}

.featured-product-item-container {
  gap: 1.25rem/* 20px */;
  margin-top: 2.5rem;
  padding-bottom: 2.5rem/* 40px */ !important;  
}

.featured-item-image-holder {
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  display: flex;
  flex-direction: column;
  justify-content: center;
  aspect-ratio: 4/5;
  margin-bottom: 15px;
}

.featured-product-image-container {
  margin: 50px auto;
}

.info-NewArrItems {
  display: flex;
  justify-content: center;
}

.info-NewArrItems-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 0.75rem/* 12px */;
}

.title-NewArrItems {
  font-size: 1rem/* 16px */;
  line-height: 1.5rem/* 24px */;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-align: left !important;
  text-transform: uppercase;
  letter-spacing: .3em;
  margin-top: 1.25rem/* 20px */;
}

.artist-NewArrItems {
  line-height: 1!important;
  font-size: .875rem;
  line-height: 1.25rem/* 20px */;
  text-align: center!important;
}


@media (max-width: 745px) {
  .image-NewArrItems {
    height: auto; /* Keep aspect ratio on smaller screens */
    width: 100%; /* Ensure images are fully responsive */
  }
}

@media (max-width: 600px) {
  .Container {
    flex: 1 1 100%; /* Full width on mobile */
    margin: 5px 0; /* Adjust margin for spacing */
    height: auto; /* Adjust height for mobile */
  }
}

@media (max-width: 745px) {
  .title-NewArrItems {
    text-align: center;
  }

}
@media (min-width: 745px) {
  .newArr-item-title {
    text-align: center;
    width: 100%;
  }
}
@media (max-width: 745px) {
  .featured-product-item-container {
    gap: 1.25rem/* 20px */;
    margin-top: 2.5rem;
    padding-bottom: 0 !important;  
  }
}