* { 
  padding: 0;
  margin: 0;
}

.shop-prints-navigation {
  column-count: 2;
  font-size: 1.4rem;
  font-family: "Optima nova", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 20px;
  column-gap: 1px; 

}

.nav-link {
  line-height: 150px;
  padding: 10px;
  display: block;
  color: #333;
  border-radius: 10px;
  margin-bottom: 10px;
  transform: translateX(5px); /* Slight movement for hover effect */
}

.nav-link:hover {
  color: gray;
  transition: .3s ease-in-out;
  padding-left: 1em;
}

.modal-x-nav .list-close.js-menu-shop-close{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  cursor: pointer;
  position: absolute;
  right: 72%;
  top: 3%;
  color: #fff;
  transition: .25s;

}

.modal-x-nav .list-close.js-menu-shop-close:hover {
  color: #f0e9d2;
  transform: scale(1.1);
  background-color: rgba(0, 0, 0, 0.85);
}

/* Shop List Styles */
.shop-list {
  height: 100%;
  background-color: #f9f9f9;
  padding: 5px;
  overflow-y: auto;
}

/* Image Container Styles */
.shop-image-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

/* Image Styles */
.shop-image {
  width: 100%;
  height: 1200px ;
  object-fit: contain; /* Ensure the image covers the container */
  transition: transform 0.3s ease-in-out;
}

.shop-image:hover {
  transform: scale(1.05); /* Subtle zoom effect on hover */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .shop-prints-navigation {
    column-count: 1; /* Single column for smaller screens */
    font-size: 1.5rem;
    margin: 10px;
  }

  .nav-link {
    line-height: 100px;
    padding: 8px;
    font-size: 1.2rem;
  }

  .modal-x-nav .list-close.js-menu-shop-close {
    right: 10%;
    top: 5%;
  }

  .shop-image-container {
    height: 50vh; /* Adjust height for smaller viewports */
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .shop-prints-navigation {
    column-count: 2;
    font-size: 1.6rem;
    margin: 15px;
  }

  .nav-link {
    line-height: 120px;
    font-size: 1.4rem;
  }

  .shop-image-container {
    height: 70vh; /* Adjust height for medium viewports */
  }
}