
nav {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: benton-sans-compressed,sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  font-style: normal;
  font-display: block;
  padding: 22px 15px;
  font-size: 16px;
  position: relative;
  z-index: 2000;
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
  padding-right: 30px;
  padding-left: 30px;
  box-sizing: border-box;
}

nav.wrapper {
  max-width: none;
}

nav:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}

nav.header-sticky {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  transition: transform .2s ease-in-out;
  z-index: 1050;
}

nav.header-sticky.show {
  transform: translateY(0); /* Show navbar */
}



.hidenav {
  transform: translateY(-100%); /* Hide navbar */
}

nav .navigation-menu-list .navigation-item:not(:last-child) {
  margin: 0 25px 0 0;
}
nav .right-hand-navigation-container .navigation-item:not(:last-child) {
  margin-right: 30px;
}

nav .navigation-item {
  padding: 0;
  line-height: 1;
}

nav .navigation-item a {
  white-space: nowrap;
  user-select: none;
  transition: .2s;
}

nav .js-menu-shop-open {
  position: relative;
  color: var(#131313);
}

nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

nav a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

menu, ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-site-header {
    border-bottom: 1px solid #eaeaea ;
    position: relative;
    width: 100%;
    top: 0;
    z-index: 1000;
    transition: transform 0.3s ease-in-out;
}

.containerNavbar {
  display: grid;
  position: relative;
  z-index: 9;
  margin: 0 auto;
  grid-template: 1fr/1fr 166px 1fr;
}

nav .containerNavbar {
  margin: 0;
  grid-template: 1fr/1fr 200px 1fr;
}

.desktop-navigation-content {
  display: none;
  height: 100%;
  text-transform: uppercase;
  width: 100%;
}

.navigation-menu-list {
  display: flex;
  align-items: center;
  height: 100%;
  list-style: none;
}

@media (min-width:600px) {
  .navigation-menu-list.right {
    /* max-width: 100px; */
    /* padding-left: 400px; */
    justify-content: end;
  }
}

.navigation-item {
  position: relative;
  padding: 0 12.5px;
}

.mobile-navigation-content {
  display: flex;
  align-items: center;
  height: 100%;
}

.mobile-navigation-hamburger-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 20px;
  cursor: pointer;
  z-index: 10;
  transition: transform 0.3s;
}

.mobile-navigation-hamburger-menu, .mobile-navigation-hamburger-menu-bar {
  
  position: absolute;
  display: block;
  width: 20px;
  height: 40px;
  top: 50%;
  transform: translateY(-50%);
}

.mobile-navigation-hamburger-menu-bar {
  width: 100%;
  height: 2px;
  background-color: #000;
  transition: transform 0.3s, opacity 0.3s;
}

/* Transform Bars to X */
.mobile-navigation-hamburger-menu.open .bar1 {
  transform: rotate(45deg) translate(5px, 5px);
}

.mobile-navigation-hamburger-menu.open .bar2 {
  opacity: 0;
}

.mobile-navigation-hamburger-menu.open .bar3 {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Overlay Navigation */
/* Navigation Links */


.centerNavbar {
  flex: 1;
  text-align: center;
}

.rightNavbar {
  flex: 1;
  display: flex;
  align-items: center !important;
  justify-content: flex-end;
}

.search-icon {
  display: flex;
  cursor: pointer;
}

.langNavbar {
  font-size: 14;
  cursor: pointer;
}

.searchContainerNB{
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
  margin-left: 25px;
  padding: 5px;
}

.inputNavbar {
  border: none;
}

.logoNavbar {
  font-weight: bold;
}

.menuItemNb {
  font-size: 14px;
  cursor: pointer;
  margin-left: 25px;
}

@media (min-width: 745px) {
  .containerNavbar  {
    margin: 0 8px;
    grid-template: 1fr/1fr 242px 1fr;
  }
}
@media (min-width: 745px) {
  nav .containerNavbar {
    margin: 0;
    grid-template: 1fr/1fr 330px 1fr;
  }
}
@media (min-width: 768px) {
  .desktop-navigation-content {
    display: block;
  }
}
@media (min-width: 745px) {
  nav {
    padding-top: 27px;
    padding-bottom: 27px;
  }
}
@media (min-width: 745px) {
  .mobile-navigation-content {
    display: none;
  }
}

@media (min-width: 745px) {}
@media (min-width: 745px) {}