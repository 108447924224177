[type=checkbox], [type=radio] {
  box-sizing: border-box;
  padding: 0;
}

@media (max-width:745px) {
  .show-on-desktop-only {
    display: none;
  }

}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 997;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,.4);
  opacity: 0;
  pointer-events: none;
  transition: opacity 150ms ease-in-out;
}


.content-container {
  position: relative;
}

.cart-container {
  margin: 0 auto;
  padding-top: 60px;
  padding-right: 20px;
  padding-bottom: 120px;
  padding-left: 20px;
  max-width: 1200px;
}

.cart-title {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: benton-sans-compressed,sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  font-style: normal;
  font-display: block;
  margin: 0;
  margin-bottom: 22px;
  text-align: center;
  letter-spacing: 1.29px;
  font-size: 36px;
}

.cart-contents-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
}

.cart-line-items-container {
  margin-right: 69px;
  width: 100%;
  max-width: 800px;


}

.cart-line-item-container {
  width: 600px;
  align-items: center;
}

.membership-savings-container {
  background-color: rgba(175,157,129,.2);
  text-align: center;
  font-size: 14px;
  padding: 24px 36px;
  position: relative;
}

@media (min-width:999px) {
  .membership-savings-container {
    margin-right: 20px;
  }
}

.membership-savings-title {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: benton-sans-compressed,sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  font-style: normal;
  font-display: block;
  max-width: 271px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 120%;
  margin-bottom: 8px;
}

.membership-savings-subtitle {
  margin-bottom: 16px;
}

.membership-savings-button {
  background-color: #000;
  max-width: 121px;
  padding: 12px;
}

@media(min-width: 999px) {
  .membership-savings-button {
    margin-left: 50px;
  }
}

.cart-line-item:first-child {
  border-top: 1px solid #eaeaea;
}

.cart-line-item {
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-column-gap: 29px;
  padding: 40px 0 45px 5px;
  border-bottom: 1px solid #eaeaea;
}

.cart-line-item-product-title {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: benton-sans-compressed,sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  font-style: normal;
  font-display: block;
  margin: 10px 0 3px;
  line-height: 24px;
  letter-spacing: 1px;
  font-size: 18px;
}

.cart-line-item-product-artist {
  margin-bottom: 14px;
  line-height: 18px;
  font-size: 16px;
}

.cart-line-item-price {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: benton-sans-compressed,sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  font-style: normal;
  font-display: block;
  text-transform: none;
  margin-bottom: 10px;
  line-height: 22px;
  letter-spacing: .67px;
  font-size: 16px;
  font-weight: bold;
}

.cart-line-item-price-type {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: benton-sans-compressed,sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  font-style: normal;
  font-display: block;
  margin-bottom: 10px;
  line-height: 22px;
  letter-spacing: .67px;
  font-size: 16px;
  font-weight: bold;
}

.cart-line-item-product-description {
  line-height: 22px;
  color: #666;
}

.cart-line-item-product-description span {
  margin-bottom: 5px;
}

.cart-line-item-quantity {
  color: #666;
}

.cart-line-item-remove {
  margin-top: 22px;
}

.cart-line-item-product-image {
  position: relative;
  height: 200px; /* Adjust based on your layout */
}

.cart-line-item-product-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2; /* Higher z-index ensures it's on top */
}

.cart-order-summary-container {
  border-top: 2px solid #2e2e2e;
  padding-top: 16px;
  height: max-content;
}

.cart-order-summary-container h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: benton-sans-compressed,sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  font-style: normal;
  font-display: block;
  display: none;
  margin: 0;
  padding-bottom: 45px;
  text-align: center;
  font-size: 22px;
}

.order-summary-shipping-container {
  display: flex;
  justify-content: space-between;
}

.order-summary-shipping {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-transform: uppercase;
}

.order-summary-cost {
  font-family: adobe-caslon-pro,serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  font-style: italic;
  font-display: block;
  letter-spacing: 0px;
  font-size: 16px;
  text-transform: initial;}

.cart-order-summary-gift-promo-code-line {
  padding: 0 0 16px;
  border-bottom: 1px solid #eaeaea;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: benton-sans-compressed,sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  font-style: normal;
  font-display: block;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.cart-order-summary-promo-code-line-value {
  font-family: adobe-caslon-pro,serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  font-style: italic;
  font-display: block;
  letter-spacing: 0px;
  font-size: 16px;
  text-transform: initial;
}

.cart-order-summary-subtotal-line {
  padding: 25px 0 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: benton-sans-compressed,sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  font-style: normal;
  font-display: block;
  font-size: 22px;
  display: flex;
  justify-content: space-between;
}

.cart-order-summary-subtotal-line-value {
  font-size: 28px;
  text-transform: none;
}

.cart-frame-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.membership-payment-option-container.checked {
  cursor: default;
}

.membership-payment-option-container.checked, .membership-payment-option-container:hover {
  background: rgba(175,157,129,.2);
}

.membership-payment-option-container.checked .radio-selector::after {
  background-color: #666;
}

.membership-payment-option-container {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 9px 12px 7px;
  cursor: pointer;
  margin-bottom: 5px;
  margin-left: -15px;
  margin-right: -15px;
  transition: background 250ms;
}

.membership-payment-option-container input {
  display: none;
}

.membership-payment-option-container .radio-selector {
  margin-right: 16px;
}

.radio-selector {
  display: inline-block;
  border: 1px solid #000;
  border-radius: 100%;
  height: 24px;
  width: 24px;
  padding: 5px;
  box-sizing: border-box;
}

.radio-selector::after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 100%;
}

input:checked.radio-selector:after{
  background-color: #000 !important;
}

.membership-payment-option-container .label-text {
  margin-top: 4px;
}

.membership-payment-option-container .price-value {
  margin-left: auto;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: benton-sans-compressed,sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  font-style: normal;
  font-display: block;
  text-transform: none;
  font-size: 16px;
  line-height: 16px;
}

.cart-order-summary-checkout-button {
  margin-bottom: 16px;
}

@media (max-width: 999px) {
  .cart-order-summary-checkout-button.primary {
    display: block;
    margin: 0 auto;
    margin-bottom: 16px;
    padding: 12px 24px;
    text-align: center;
  }
}
.cart-order-summary-tax-message {
  font-family: adobe-caslon-pro,serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  font-style: italic;
  font-display: block;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 24px;
  color: #666;
}

.cart-order-summary-continue-shopping {
  width: 100%;
  font-size: 16px;
  color: #666;
  text-align: center;
  margin-top: 28px;
}

@media (min-width: 745px) {
  .cart-title {
    line-height: 1.13;
    font-size: 60px;  
  }
}

@media (min-width: 999px) {
  .cart-contents-container {
    flex-direction: row;
    max-width: 1280px; 
  }
}

@media (min-width: 745px) {
  .cart-line-items-container {
    width: calc(100% - 100px);
    align-items: center;
    margin: 0 auto;
    
  }
}

@media (min-width: 660px) {
  .cart-line-item {
    grid-template-columns: 150px 1fr 40px 111px;
    grid-column-gap: 9%;
  }
}

@media (min-width: 745px) {
  .cart-line-item-product-title {
    font-size: 24px;
  }
}

@media (min-width: 745px) {
  .cart-line-item-product-artist {
    margin-bottom: 23px;
    line-height: 1.13;
  }
}

@media (min-width: 745px) {
  .cart-line-item-price {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 18px;
    text-align: right;
  }
}

@media (min-width: 745px) {
  .show-on-mobile-only {
    display: none !important;
  }
}

@media (min-width: 745px) {
  .cart-line-item-price-type {
    text-align: right;
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 18px;  }
}

@media (min-width: 745px) {
  .cart-line-item-product-description {
    line-height: 19px;
  }
}

@media (min-width: 745px) {
  .cart-line-item-quantity {
    margin-top: 4px;
    border: 1px solid #eaeaea;
    padding: 11px 16px 14px;
    max-height: 40px;
    text-align: center;
    color: #000;
  }
}
@media (max-width: 660px) {
  .cart-line-item-quantity.show-on-desktop-only {
    display: none;
  }
}
@media (min-width: 999px) {
  .cart-order-summary-container {
    border: solid 1px #2e2e2e;
    padding: 42px 43px 49px 40px;
    width: 411px;

    position: sticky;
    top: 100px;
  }
}

@media (max-width: 999px) {
  .cart-order-summary-container {
    margin-right: 10px;
    margin-left: 10px;
  }
}

@media (min-width: 745px) {
  .cart-order-summary-container h3 {
    display: block;
  }
}
@media (min-width: 999px) {
  .membership-savings-container {
    display: flex;
    text-align: left;
    align-items: center;
  }
}
@media (min-width: 745px) and (max-width: 999px)  {
  .membership-savings-container {
    display: flex;
    text-align: left;
    align-items: center;
    height: auto;
    align-self: stretch; /* Ensures it stretches vertically */
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
@media (min-width: 745px) {
  .membership-savings-title {
    max-width: none;
  }
}
@media (min-width: 745px) {
  .membership-savings-subtitle {
    margin-bottom: 0;
  }
}
@media (min-width: 745px) {}
@media (min-width: 745px) {}
