.product-carousel-item:hover {
  opacity: 0.75;
}
.carousel-wrapper {
  position: relative;
  width: 100%;
  /* overflow: hidden; */
}

@media (min-width: 600px){

  .carousel-arrow {
    display: none;
  }
}
@media (max-width: 600px) {
  .carousel-card-container {
    display: flex;
    gap: 12px;
    padding: 10px 0;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    max-width: 100%;
    /* position: relative; */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide scrollbar for Internet Explorer */
  }
  
  .product-carousel-item {
    flex: 0 0 auto;
    width: 50%; /* Adjust width for better visibility */
    scroll-snap-align: center;
    position: relative;
  }

  .carousel-card-container::-webkit-scrollbar {
    display: none;
  }

  .carousel-arrow {
    position: absolute;
    top: 75%;
    transform: translateY(-50%);
    font-size: 32px;
    color: black;
    opacity: 0.4;
    user-select: none;
    z-index: 10;
    pointer-events: none; /* Make arrows non-clickable */
  }

  .left-arrow {
    left: 10px;
  }
  
  .right-arrow {
    right: 10px;
  }
  
 
}

.carousel-items-container {
  margin-top: 1.5rem/* 24px */;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 2.5rem/* 40px */;
  column-gap: 1.5rem/* 24px */;
  overflow-x: hidden;
}



.carousel-item-container {
  display: block;
  position: relative;
  text-align: center;
  text-decoration: none;
  
  width: 100%;
  min-height: 250px;
  border-radius: 6px;
  overflow: hidden;
  aspect-ratio: 1/1;

}

.carousel-item-image-holder {
  position: relative;
  display: block;
  padding-top: 0 !important;
}

.carousel-item-image {
  position: relative;
  top: 2.2px;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.carousel-item-frame-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%; 
  pointer-events: none;
  z-index: 1;
}

.carousel-item-title-container {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: benton-sans-compressed, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  font-style: normal;
  font-display: block;
  margin-top: 10px;
  margin-bottom: 4px;
  text-align: center;
  text-decoration: none;
  font-size: 24px;
  position: relative;
}
@media (max-width: 768px) {
  .carousel-item-title-container {
    font-size: 18px;
  }
}
.carousel-item-artist {
  margin-top: 0.25rem/* 4px */;
  font-family: adobe-caslon-pro, serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  font-display: block;
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  display: inline-block;
  position: relative;
}

.carousel-item-options-container {
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  display: inline-block;
  position: relative;
  font-weight: 400;
  font-style: italic;
  margin-bottom: 10px;
}

@media (min-width:640px) {
  .carousel-items-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width:1024px) {
  .carousel-items-container {
    grid-template-columns: repeat(5, minmax(0, 1fr));  
  }
}

@media (min-width:1280px) {
  .carousel-items-container {
    column-gap: 2rem/* 32px */;  
  }
}

@media (min-width:1024px) {
  .carousel-item-container {
    height: 20rem/* 320px */;
    aspect-ratio: none;
  }
}

@media (min-width:1024px) {
  .carousel-item-image {
    height: 100%;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .carousel-item-container {
      margin-right: 40px;
  }
}

