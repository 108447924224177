a {
  color: inherit;
  text-decoration: inherit;
  transition: color .3s ease-out;
}

span {
  padding: 0;
  display: inline;
}

h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

img {
  max-width: 100%;
  height: auto;

}

button {
  text-transform: none;

  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;

  overflow: visible;
}

input {
  overflow: visible;
}

label { 
  cursor: default;
}

form {
  display: block;
  margin-top: 0em;
}

option {
  font-weight: normal;
  display: block;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  min-height: 1.2em;
  padding: 0px 2px 1px;
}

.section {
  margin-bottom: 30px;
}

.wrapper-full-width{
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.desktop-only {
  display: flex;
  flex-direction: column;
}

.main-product-section-size-selectors-container-mobile-only {
  display: none;
  margin-bottom: 20px;
}

.tooltip {
  height: 16px;
  width: 16px;
  background-size: cover;
  background-color: var(--cr-white);
  cursor: pointer;
}

.object-fit {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.product-main-container {
  position: relative;
}

.product-breadcrumbs-container-main {
  margin-top: 16px;
  margin-bottom: 8px;
  display: block;
  line-height: normal;
}
.product-breadcrumb-container {
  display: inline-block;
  margin-right: 9px;
  font-weight: 400;
}

.product-breadcrumb {
  color: #999;
}

.product-page-main-product-section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.object-fit {
  width: 28.5%;
  height: 50%;
}

.product-section-images-container {
  opacity: 0;
  height: fit-content;
  position: sticky;
  top: 50px;
  display: flex !important;
  flex-direction: column !important;
  transition: opacity .15s;
  width: 100%;
}
.product-section-images-container.loaded {
  opacity: 1;
}

.product-section-images {
  position: relative;
  width: 90%;
  margin: 0 auto;
}

.product-item-image-holder {
  position: relative;
  display: block;
  padding-top: 0 !important;
  transition: opacity .15s;
  width: 100%;
  height: auto;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;
}

.product-item-images-final {
  width: 55%;
  margin: 0 auto;
}
@media (max-width: 600px) {
  .product-item-images-final {
    width: 100%;

  }

}
.product-item-image {
  position: relative; 
  justify-content: center;
  z-index: 2; 
  top: 2.2px;
}
@media (min-width: 780px){
  .product-header {
    display: none;
  }

}

.frame-overlay-final {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  height: 95%; 
  pointer-events: none; 
  z-index: 1;
}


.product-section-save-button {
  gap: 0.25rem/* 4px */;
  display: flex;
  align-items: center;
  padding: 6px;
  opacity: 0.8;
  border: none;
  margin: 0 auto;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.product-section-save-button-text {
  min-width: 38px;
  font-size: 0.875rem/* 14px */;
  line-height: 1.25rem/* 20px */;
  text-align: left;
}
.save-button-text-save {
  line-height: 1;
  padding: 5px;
}
.save-button-text-save:hover {
  text-decoration: underline;
}

.save-button-text-saved {
  line-height: 1;
  display: none;
}

.product-section-form-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-self: center;
  padding-top: 20px;
  width: 100%;
  max-width: 471px;
}

.main-product-top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: flex-start;
}

.product-review-container {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;

  margin-bottom: 5px;
}

.product-review-summary {
  display: flex;

  
}

.review-summary-starRating {
  margin-right: .25em;
  display: inline-block;
}

.starRating-small {
  display: inline-block;
  height: 25px;
  position: relative;
  vertical-align: middle;
  width: 75px;
}

.product-title {
  margin-bottom: 4px;
  font-size: 40px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: .025em;
  -webkit-font-smoothing: antialiased;
}

@media (max-width: 600px) {
  .product-title {
    font-size: 40px;
  }
}

.artist-location-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px/1.55;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.artist-location {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1;
  font-weight: bold;
  font-family:  'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.artist-location span {
  display: flex;
}

@media (max-width: 435px) {
  .artist-location-text {
    white-space: nowrap;
    
  }
}
.divider {
  padding: 0 10px;
}

.artist-link {
  display: inline-block;
  line-height: 1;
  text-decoration: none;
  border-bottom: 1px solid #000;
}

.main-product-buying-panel--pricing {
  margin-bottom: 20px;
}

.non-member-price {
  padding-bottom: 0px;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 1px;
  font-weight: 700;
  color: rgba(102,102,102,.6);
  text-transform: uppercase;
}

.member-price {
  font-size: 24px;
  margin-bottom: 0.625rem/* 10px */;
  font-weight: bold;
  cursor: pointer;

  text-transform: uppercase;
  letter-spacing: 1px;
  -webkit-font-smoothing: antialiased;
}

@media (max-width: 600px) {
  .non-member-price,.member-price  {
    font-size: 20px;
  }
}
.product-shipping {
  margin-bottom: 0.5rem/* 8px */;
  font-style: italic;
}

/* .dropdown-list {
  display: block !important;
} */

.main-product-section-size-selector-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 19px;
  align-items: baseline;
}

.main-product-section-size-selector-title-header {
  display: flex;
  margin-top: 0;
  margin-bottom: var(16px);
  width: 100%;
  justify-content: space-between;
}

.main-product-section-size-selector-final-dimensions {
  display: flex;
  align-items: center;
}

.main-product-section-size-selector-label {
  margin-right: 5px;
  margin: 0 4px 0 0;
  padding-bottom: 0;

  text-transform: uppercase;
  letter-spacing: .07143em;
  font-size: 14px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif ;
  -webkit-font-smoothing: antialiased
}
.size-selector-measurement {
  text-transform: none;
  color: #666666;
  line-height: 1;
  padding-bottom: 2px;
  -webkit-font-smoothing: antialiased;
  font-style: italic;
  font-size: 16px;
  font-family: adobe-caslon-pro,Georgia,serif;
  letter-spacing: 0;

  
}

.main-product-section-size-selector-size-guide {
  display: flex;
  align-self: center;
  margin-right: 12px;
}

.main-product-section-size-selector-size-guide {
  display: flex;
  align-self: center;
  margin-right: 12px;
}
.the-tray-open-button-sg {
  padding-top: 4px;
  margin-left: 8px;
  border-bottom: 1px solid black;
  line-height: 1;
  background-color: rgba(0,0,0,0);
  cursor: pointer;
  transition: all .3s ease-out;
  transition-property: color,border-color;
  font-family: 'Courier New', Courier, monospace;
}

.main-product-section-size-selectors-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 27px;
}

.main-product-section-size-selector-input {
  width: 0;
  display: none;
  padding: 0;
}

.main-product-section-size-selector {
  display: grid;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border: 1px solid #eaeaea;
  cursor: pointer;
  font-size: 16px;

  grid-template-columns: 80px 1fr 80px;
  padding: 12px 21px;
  border-radius: 0px;
}

input[type=radio]:hover+.main-product-section-size-selector {
  border: solid #000 1px;
}

.main-product-section-size-selector-name {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: benton-sans-compressed,sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

.main-product-section-size-selector-dimensions {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0;
  text-transform: lowercase;
  font-style: italic;
  margin-top: 3px;

  text-align: center;
  color: #666;
}

.main-product-section-size-selector-price {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: benton-sans-compressed,sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  font-style: normal;
  font-display: block;
  text-transform: none;
  text-align: right;
}

.main-product-frame-selector-container, .main-product-mount-selector-container {
  margin-bottom: 10px;
}

.main-product-frame-selector-title {
  margin-bottom: 6px;

  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: benton-sans-compressed,sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  font-style: normal;
  font-display: block;
  color: #000;
  font-size: 16px;

}

.frame-selector-label {
  margin: 0 4px 0 0;
  text-transform: uppercase;
  letter-spacing: .07143em;
  font-size: 14px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}

.frame-selector-price {
  font-family: adobe-caslon-pro,serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  font-style: italic;
  font-display: block;
  display: inline-block;
  margin-right: 10px;
  background-color: #eaeaea;
  padding: 7px 7px 0;
  text-transform: none;
  line-height: 1;
  letter-spacing: 0;
  color: #999;
  font-size: 14px;
  
}

.frame-selector-color {
  margin: 0 8px 0 0;
  color: #666666;
  -webkit-font-smoothing: antialiased;
  font-style: italic;
  font-size: 16px;
  font-family: adobe-caslon-pro,Georgia,serif;
  text-transform: none;
  letter-spacing: 0;
}

.main-product-frame-selectors-container {
  display: flex;
  margin-bottom: 23px;
  column-gap: 17px;
}

.frame-selector-input {
  width: 0;
  display: none;
  padding: 0;
}

.frame-selector-swatch-label{
  padding: 7px;
  border: 1px solid rgba(0,0,0,0);
  border-radius: 100%;
  transition: background-color .4s 
    cubic-bezier(0.39, 0.575, 0.565, 1);
}

input[type=radio]:hover + .frame-selector-swatch-label,
.default-selected .frame-selector-swatch-label {
  border: solid #000 1px;
}

.frame-selector-swatch {
  height: 33px;
  width: 33px;

  position: relative;
  border-radius: 100%;
  background-color: #eaeaea;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  overflow: hidden;
  transition: 
    background-color .4s cubic-bezier(0.39, 0.575, 0.565, 1) 
    opacity .4s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.frame-selector-swatch.no-frame {
  border: 1px solid #c6c6c6;
  background-color: #ededed;
}

.frame-selector-swatch.white {
  border: 1px solid #ededed;
}

.frame-selector-swatch.no-frame::before {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateZ(45deg);
  background-color: #c6c6c6;
  width: 150%;
  height: 1px;
  content: "";
  transform-origin: left;
}

.main-product-mount-selector-container {
  margin-bottom: 10px;
}

.main-product-mount-selector-title {
  margin-bottom: 6px;
}

.mount-selector-label {
  margin: 0 4px 0 0;
  text-transform: uppercase;
  letter-spacing: .07143em;
  font-size: 14px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
}

.mount-selector-border {
  color: #666666;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
  padding-top: 2px;
  font-family: adobe-caslon-pro,Georgia,serif;
  font-style: italic;
  text-transform: none;
  font-size: 16px;
  letter-spacing: 0;
}

.main-product-mount-selectors-container {
  display: flex;
  margin-bottom: 23px;
}

.mount-selector-input {
  width: 0;
  display: none;
  padding: 0;
  visibility: hidden;
}

input[type=radio]:checked+.mount-selector {
  border: solid #000 1px;
}

input[type=radio]:hover+.mount-selector {
  border: solid #000 1px;
}

.mount-selector {
  padding: 10px 12px;
  border-radius: 0px;
  border-radius: 0px;
  border: 1px solid #eaeaea;

  margin-right: 10px;
  width: 50%;
  font-size: 14px;
  cursor: pointer;
}

.mount-selector-name {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  font-style: normal;
  font-display: block;
  text-align: center;
}

.main-product--add-to-cart-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem/* 8px */;

}

.add-to-cart {
  margin-bottom: 8px;
}

.hos-button {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: benton-sans-compressed,sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  font-style: normal;
  font-display: block;
  display: inline-block;
  position: relative;
  transition: background-color 250ms,color 250ms,border-color 250ms;
  border: 1px solid #2e2e2e;
  background-color: rgba(0,0,0,0);
  cursor: pointer;
  padding: 18px 15px 15px;
  width: 100%;
  max-width: 350px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: #2e2e2e;
  font-size: 14px;
  box-sizing: border-box;
  fill: #2e2e2e;
}

.hos-button.primary {
  background-color: #2e2e2e;
  color: #fff;
  fill: #fff;
}

button[type=submit] {
  background-image: none;
}

.main-product-section-add-to-cart-button {
  max-width: 100%;
  margin-bottom: 0px !important;
}

.save-button-container {
  margin-bottom: 1rem/* 16px */;
}

.save-button {
  gap: 0.375rem/* 6px */;
  display: flex;
  align-items: center;
  justify-content: center;

  border-width: 1px;
  min-height: 56px;
  width: 100%;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.875rem/* 14px */;
  line-height: 1.25rem/* 20px */;
  letter-spacing: 1px;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.save-button:hover {
  opacity: 0.7;
}

.save-button-text {
  min-width: 38px;
  text-align: left;
}

.save-button-text-check {
  line-height: 1;
  display: block;
}

.save-button-text-checked {
  display: none;
  line-height: 1;
}

.shipping-message-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.shipping-message {
  font-style: italic;
  font-size: 16px;
  font-family: adobe-caslon-pro, Georgia, serif;
  -webkit-font-smoothing: antialiased;
}

.carousel-title {
  text-align: center;
  margin: 0;
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: .04167em;
  font-size: 24px;
}

.carousel-container {
  overflow: hidden;
  margin: 0 auto;
  max-width: fit-content;
}

.carousel {
  display: grid;
  grid-auto-flow: column;
  gap: 20px;
  overflow-x: auto;
  overscroll-behavior-inline: contain;
  scroll-padding-inline: 20px;
  padding-block-end: 30px;
  scrollbar-width: none;
  margin: 0 auto;
  
}

.carousel-card-image {
  max-width: unset;
  aspect-ratio: 5/5;
  object-fit: cover;
  height: 250px;
  display: block;
}

.carousel-card-footer {
  margin-top: 12px;
}

.carousel-card-title {
  margin: 0 2px 0 0;
  display: inline-block;
}

.carousel-card-copy {
  margin: 0;
  display: inline-block;
}

.product-information-section {
  margin: 0 auto 10px;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 50px;
}

.product-information-navigation-container {
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #eaeaea;
  padding: 28px 0 16px;
}
@media (max-width: 820px) {
  .product-information-navigation-container {
    display: none;
  }
}

.product-information-navigation {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 540px;
}

.product-information-navigation-item {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: benton-sans-compressed,sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  padding: 10px 0;
  letter-spacing: 1px;
  color: #999;
  font-size: 14px;


}

.product-information-navigation-item.active {
  font-weight: 700;
  color: #000;
  border-bottom: 2px solid #000; 
  margin-bottom: -18px;
}



.product-information-text-container {
  max-width: 100%; /* Ensures it doesn't overflow */
  padding: 20px; /* Adjust as needed for spacing */
  margin: 0 auto; /* Centers it within the page */
  box-sizing: border-box;
  user-select: auto;
}
@media (max-width: 919px) {
  .product-information-text-container {
    width: 100% !important;
  }
}

.product-information-mobile-title {
  display: none;
}

@media (max-width: 999px) {
  .product-information-mobile-title {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: benton-sans-compressed, sans-serif;
    letter-spacing: 1px;
    text-transform: uppercase;
    max-width: 471px;
    width: 100%; 
  }
}

.product-information-mobile-title h4 {
  margin: 0;
  font-size: 1.1em;
}

.product-information-mobile-title .rel-plus,
.product-information-mobile-title .rel-minus {
  display: none;
}

.product-information-mobile-title .rel-plus.active,
.product-information-mobile-title .rel-minus.active {
  display: inline;
}
.product-information-text-contents {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 49px;
  line-height: 24px;
  color: #666;
  font-size: 16px;
}

.product-information-text-title {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: benton-sans-compressed,sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  font-style: normal;
  font-display: block;
  margin-bottom: 17px;
  line-height: 18px;
  letter-spacing: 1px;
  color: #000;
  font-size: 22px;
}

.rel-plus {
  display: block;
    font-size: 24px;
    font-weight: bold;
    font-family: "adobe-caslon-pro",serif;
}

.rel-minus {
  display: none;
    font-size: 24px;
    font-weight: bold;
    font-family: "adobe-caslon-pro",serif;
}

.product-slick-track::before {
  display: table;
    content: '';
}

.product-slick-track::after {
  clear: both;
  display: table;
  content: '';
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.product-information-navigation-container.product-slick-list {
  overflow: unset;
}

.product-information-navigation-container.product-slick-track {
  display: flex;
  transform: translate3d(0px, 0px, 0px) !important;
  margin: auto;
}

.product-slick-slider.product-slick-list {
  transform: translate3d(0, 0, 0);
}

.product-information-navigation-item.slick-current {
  position: relative;
  color: #000;
}

.product-information-navigation-item.slick-current::after {
  position: absolute;
  bottom: -18px;
  left: 0;
  background-color: #000;
  width: 100%;
  height: 2px;
  content: "";
}

.product-slick-initialized .slick-slide {
  display: block;
}

.product-information-column p {
  margin: 0;
  margin-bottom: 28px;
}

.product-carousel-container {
  
  max-width: 1280px;
  /* margin-left: auto;
  margin-right: auto; */
  padding-top: 1rem/* 48px */;
  padding-bottom: 20px;
  padding-left: 1rem/* 16px */;
  padding-right: 1rem/* 16px */;
}

.product-carousel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
  padding-bottom: 1.5rem/* 24px */;
}

.product-carousel-header-title {
  font-size: 1.5rem/* 24px */;
  line-height: 2rem/* 32px */;
  font-weight: 700;
  letter-spacing: -0.025em;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.product-carousel-header-link {
  font-size: 0.875rem/* 14px */;
  line-height: 1.25rem/* 20px */;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

.product-carousel-header-link:hover {
  --tw-text-opacity: 1;
    color: rgb(99 102 241 / var(--tw-text-opacity));

}





.popup-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.toast {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: opacity 0.3s ease-in-out;
}

.toast.active {
  opacity: 1;
  visibility: visible;
}

.toast-content {
  display: flex;
  align-items: center;
}

.check {
  margin-right: 10px;
}

.message .text-1 {
  font-weight: bold;
}

.message .text-2 {
  font-size: 0.9em;
}

.close {
  cursor: pointer;
}

.progress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  background-color: #4caf50;
  width: 100%;
  transition: width 5s linear;
}

.progress.active {
  width: 0;
}

.product-page-container {
  display: flex;
  flex-direction: column;
}

@media (min-width:920px) {
  .product-breadcrumbs-container {
    padding-right: 30px;
    padding-left: 30px;
    margin: 24px 0 0 0;
    text-align: left;
  }
}

@media (min-width: 768px) {
  .product-page-container {
    padding-top: 20px;
    width: calc(100% - 70px);
    max-width: 2240px;
  }
}

@media (min-width: 920px) {
  .product-page-main-product-section {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 100px;
  }
}

@media (max-width: 820px) {
  .show-mobile-only {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 15px 0;
  }
}

@media (min-width: 920px) {
  .product-section-image-viewer-container {
    width: 91.2vh;
    /* max-width: calc(100% - 550px); */
    display: inline-block;
    float: left;
    position: sticky;
    top: 50px;
  }
}
@media (max-width: 600px) {
  .product-section-image-viewer-container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;

  }
}

@media (min-width:920px) {
  .product-section-images {
    cursor: zoom-in;
  }
}

@media (min-width:920px) {
  .product-section-image {
    object-position: top center;
  }
}

@media (min-width: 768px) {
  .product-section-save {
    display: flex;
    display: none;
    justify-content: center;
    width: 100%;
    position: absolute;
    z-index: 2;
    bottom: -32px;

  }
}

@media (min-width: 920px) {
  .product-section-save {
    display: flex;
  }
}

@media (min-width: 920px) {
  .artist-location-container {
    justify-content: flex-start;
    margin-bottom: 24px;
  }
}

@media (min-width: 920px) {
  input[type=radio]:checked+.main-product-section-size-selector {
    display: grid;
    order: unset;
    border-color: #000;

    grid-template-columns: 80px 1fr 80px;
    padding: 12px 21px;
    border-radius: 0px;
  }
}

@media (min-width: 920px) {
  input[type=radio]:checked+.frame-selector-swatch-label {
    display: flex;
    border: 1px solid #000;
  }
}

@media (min-width: 745px) {
  .product-information-section {
    margin-bottom: 120px;
    padding-right: 0;
    padding-left: 0;
    max-width: 2240px;
  }
}

@media (min-width: 769px) {
  .product-information-text-contents {
    flex-direction: row;
  }
}

@media (min-width: 745px) {
  .product-information-column {
    width: 40%;
  }
}

@media (min-width: 745px) {
  .product-information-column:last-child {
    width: 45%;
  }
}

@media (min-width: 745px) {
  .frame-overlay-final {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; 
    height: 100%; 
    pointer-events: none; /* Allows clicking through the frame overlay to the product image link */
    z-index: 1;
  }
}

@media (min-width: 920px) {
  .product-item-images {
    cursor: zoom-in;
  }
}

@media (min-width:768px) {
  .product-carousel-container {
    padding-left: 1.5rem/* 24px */;
    padding-right: 1.5rem/* 24px */;
  }
}

@media (min-width:1024px) {
  .product-carousel-container {
    padding-left: 2rem/* 32px */;
    padding-right: 2rem/* 32px */;
  }
}


@media (max-width: 820px) {
  .main-product--desktop-only {   
    display: none;
  }
}

@media (max-width: 768px) {
  .desktop-only {
    display: none;
  }

  .main-product-section-size-selectors-container-mobile-only {
    display: flex;
    flex-direction: column;  
  }

  .size-dropdown-container {
    position: relative;
    width: 100%;
    font-family: inherit;
    cursor: pointer;
  }

  .dropdown-select-items-sizes {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    background-color: white;
    transition: background-color 0.3s;
  }

  .selected-value {
    /* display: flex;
    flex-direction: row; */
    display: grid;
        order: unset;
        border-color: #000;
        grid-template-columns: 80px 1fr 80px;
        padding: 1px 10px 0px 0px;
        border-radius: 0px;
        width: 100%;
    
  }

  .main-product-section-size-selector-name {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    padding-right: 5px ;
  }

  .main-product-section-size-selector-dimensions,
  .main-product-section-size-selector-price {
    font-size: 14px;
    color: #666;
  }

  .arrow-sizes {
    border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transition: transform 0.3s;
  }

  .down {
    transform: rotate(45deg);
    
  }

  .up {
    transform: rotate(-135deg);
    
  }

  /* Dropdown list styling */
  .dropdown-list-sizes {
    /* position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #eaeaea;
    z-index: 10;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 240px;
    overflow-y: auto; */
  }

  .dropdown-list-text-sizes {
    display: grid;
    order: unset;
    border-color: #000;
    grid-template-columns: 80px 1fr 80px;
    padding: 12px 24px 12px 10px;border: 1px solid #eaeaea;
    border-radius: 4px;
    background-color: white;
    transition: background-color 0.3s;
  }

  .dropdown-list-text-sizes:hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }
}
/* @media (min-width: 768px) {
  .product-information-text {
    border-bottom: 1px solid #eaeaea;
        padding: 0 ;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
  } 
}

@media (min-width: 768px) {
  .product-information-text:first-of-type {
    border-top: 1px solid #eaeaea;
  }
  
} */

@media (min-width: 821px) {
  /* Ensure only one tab is visible at a time */
  .product-information-text {
    display: block; /* Each section is individually displayed */
  }



  /* Plus and minus icon handling */
  .rel-plus,
  .rel-minus {
    font-size: 1.2em;
    font-weight: bold;
  }

  /* Show the correct icon based on whether tab is open or closed */
  .product-information-text.active .rel-plus {
    display: none;
  }

  .product-information-text:not(.active) .rel-minus {
    display: none;
  }
}